import React from "react";
import vREFYD_PURPLE from "../Assets/images/vREFYD_purple.png";
import BACKICON from "../Assets/images/back-icon.png";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../constants/route-path";
import "./loginWrapper.css";
import LoaderComponent from "./loader";
import AlertMessage from "./alertmessage";

const LoginWrapper = (props) => {
  const {
    showBack = false,
    loading,
    openSnackbar,
    setOpenSnackbar,
    setAlertmsg,
    alertType,
    alertMsg,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="login-container">
      <div className="login-content-section">
        <div className="login-hero-section">
          <span className="login-hero-main-title">
            Find, Secure, Close Faster.
          </span>
          <span className="login-hero-section-info">
            FIND verified properties, connect with a trusted community, and use
            AI-powered tools to close transactions quickly and securely.
          </span>
        </div>
        <div className="login-input-container">
          <div className="login-input-logo-section">
            <img
              src={vREFYD_PURPLE}
              alt="vREFyd Logo"
              className="login-input-brand-logo"
            />
          </div>
          {showBack && (
            <div onClick={() => navigate(LOGIN)} className="login-back-button">
              <img
                src={BACKICON}
                alt="back"
                className="login-back-button-icon"
              />
            </div>
          )}

          <div className="login-input-content">
            {React.cloneElement(props.children, { ...props })}
          </div>
        </div>
      </div>
      <span className="footer-note">
        You're currently using an internal Beta version.
      </span>

      {loading && <LoaderComponent />}

      <AlertMessage
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        setAlertmsg={setAlertmsg}
        alertType={alertType}
        alertMsg={alertMsg}
      />
    </div>
  );
};

export default LoginWrapper;
