import { LOCAL_STORAGE_DATA } from "../constants/app-constants";

export const getAccessToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_DATA.ACCESS_TOKEN);
};

export const getRefreshToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_DATA.REFRESH_TOKEN);
};

export const getUserData = () => {
   const userDetails = localStorage.getItem(LOCAL_STORAGE_DATA.USER);
    if (userDetails) {
      return JSON.parse(userDetails);
    } else return expireSesion();
  
};

export const expireSesion = () => {
  localStorage.clear();
  window.location.replace("/");
  return null;
};

export const getInitials=(name)=> {
  if(name){
    const words = name.split(' ');
    const initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase());
    return initials.join(' ');
  }
  return '';
}
