import React, { useState } from "react";
import { TextField, Button, Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./SignupLoginForm.css"; // Ensure you have this CSS file for custom styles
import vREFYD_PURPLE from "../../Assets/images/vREFYD_purple.png";
// import FB_ICON from "../../Assets/images/fb.png";
// import GOOGLE_ICON from "../../Assets/images/google.png";
// import WHATSAPP_ICON from "../../Assets/images/whatsapp.png";
import { VERIFY_OTP } from "../../constants/route-path";
import apis from "../../Services/api-services";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../Store/userslice";
import AlertMessage from "../../components/alertmessage";
import { ALERT_TYPES } from "../../constants/app-constants";
import LoaderComponent from "../../components/loader";
import LoginWrapper from "../../components/loginWrapper";

const SignupLoginForm = (props) => {
  // const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  // const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  // const { userDetails } = useSelector((state) => state.userDetails);
  const [openSnackbar, setOpenSnackbar] = useState(false); // State to control error Snackbar
  const [alertMsg, setAlertmsg] = useState("");
  const [alertType, setAlertType] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  // const regexMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //143806 - SAFD - Gayathri - 13-june-22
  const regexOnlyNumber = /^[0-9]*$/;
  const wrapperProps = {
    loading,
    openSnackbar,
    setOpenSnackbar,
    setAlertmsg,
    alertType,
    alertMsg,
  };

  const validateForm = () => {
    const newErrors = {};
    // if (!name) newErrors.name = "Name is required";
    if (!mobile) {
      newErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      newErrors.mobile = "Enter a valid 10-digit Indian mobile number";
    }
    // if (!email) {
    //   newErrors.email = "Email is required";
    // } else if (!/\S+@\S+\.\S+/.test(email)) {
    //   newErrors.email = "Email address is invalid";
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    const payload = { mobile, channel: "android" };
    setLoading(true);
    apis.user.sendOtp(payload).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        dispatch(userActions.setUserDetails({ mobile }));
        navigate(VERIFY_OTP);
      } else {
        setAlertmsg("Login Failed!");
        setAlertType(ALERT_TYPES.error);
        setOpenSnackbar(true);
        console.error("Signup failed:", response);
      }
    });
  };

  // const handleSocialLogin = (provider) => {
  //   console.log(`Social login with ${provider}`);
  // };

  const handleInputChange = (value, id) => {
    let newErrors = {};
    switch (id) {
      // case "email":
      //   setEmail(value);
      //   break;
      case "mobile":
        if (regexOnlyNumber.test(value) && value.length < 11) {
          setMobile(value);
        }
        break;
      // case "name":
      //   setName(value);
      //   break;

      default:
        break;
    }
    setErrors(newErrors);
  };

  return (
    <LoginWrapper {...wrapperProps}>
      <div className="signup-login-form-content">
        {/* <span className="sign-up-title">Let's get started..</span> */}

        <form onSubmit={handleSubmit}>
          {/* <span className="sign-up-label">Name</span>
            <TextField
              // label="Name"
              placeholder="Name"
              variant="outlined"
              fullWidth
              margin="none"
              id="name"
              value={name}
              onChange={(e) => handleInputChange(e.target.value, e.target.id)}
              error={!!errors.name}
              className="signup-inputs"
              helperText={errors.name}
              sx={{
                "& .MuiInputBase-input": { height: "6px" },
              }}
            /> */}
          <span className="sign-up-label">Enter Mobile Number</span>
          <TextField
            // label="Mobile Number"
            placeholder="9999999999"
            variant="outlined"
            fullWidth
            margin="none"
            id="mobile"
            value={mobile}
            onChange={(e) => handleInputChange(e.target.value, e.target.id)}
            error={!!errors.mobile}
            className="signup-inputs"
            helperText={errors.mobile}
            sx={{
              "& .MuiInputBase-input": { height: "6px" },
            }}
          />
          {/* <span className="sign-up-label">Email</span>
            <TextField
              // label="Email"
              placeholder="abc@gmail.com"
              id="email"
              variant="outlined"
              fullWidth
              margin="none"
              value={email}
              onChange={(e) => handleInputChange(e.target.value, e.target.id)}
              error={!!errors.email}
              className="signup-inputs"
              helperText={errors.email}
              sx={{
                "& .MuiInputBase-input": { height: "6px" },
              }}
            /> */}
          <Button
            type="submit"
            variant="contained"
            className="signup-btn"
            color="primary"
            fullWidth
          >
            SUBMIT
          </Button>
        </form>

        {/* <Divider className="signup-text-color sign-up-devider">Or</Divider> */}
        {/* <span className="sign-up-social-login-title signup-text-color">
            Create account with
          </span> */}
        {/* <div className="sign-up-social-logins">
            <div onClick={() => handleSocialLogin("Google")}>
              <img
                src={GOOGLE_ICON}
                alt="facebook-login"
                className="social-icons"
              />
            </div>
            <div onClick={() => handleSocialLogin("Facebook")}>
              <img
                src={FB_ICON}
                alt="facebook-login"
                className="social-icons"
              />
            </div>
            <div onClick={() => handleSocialLogin("Facebook")}>
              <img
                src={WHATSAPP_ICON}
                alt="facebook-login"
                className="social-icons"
              />
            </div>
          </div> */}

        {/* <div>
            <span
              className="login-footer-text signup-text-color"
              onClick={() => {}}
            >
              Already have an account?{" "}
              <span className="footer-text-login">Login</span>
            </span>
          </div> */}
      </div>
    </LoginWrapper>
    // <div className="signup-login-form-container">

    //   <Box className="signup-login-form box-shadow">

    //     <span className="footer-note">You're currently using an internal Beta version.</span>

    //   </Box>
    //   {loading &&<LoaderComponent />}

    //   <AlertMessage
    //     openSnackbar={openSnackbar}
    //     setOpenSnackbar={setOpenSnackbar}
    //     setAlertmsg={setAlertmsg}
    //     alertType={alertType}
    //     alertMsg={alertMsg}
    //   />
    // </div>
  );
};

export default SignupLoginForm;
