import React, { useState, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import Sidebar from "./Pages/Sidebar/Sidebar";
// import ChatWindow from './components/ChatWindow';
import SignupLoginForm from "./Pages/SignupLogin/SignupLoginForm";
// import OTPVerificationForm from './components/OTPVerificationForm';
// import SuccessScreen from './components/SuccessScreen';
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import apis from "./Services/api-services";
import { getAccessToken } from "./Utils/helperUtils";
import {
  LOGIN,
  VERIFY_OTP,
  OTP_SUCCESS,
  CHAT_WITH_AIRA,
  FOUR_0_FOUR,
  HOME,
  UPDATE_DATAILS,
} from "./constants/route-path";
import LoaderComponent from "./components/loader";
import ErrorBoundary from "./components/errorBoundary";
import Protected from "./Utils/protectedRoute";
import { userActions } from "./Store/userslice";
import { loginActions } from "./Store/loginSlice";

// Lazy Routes
const ChatWindow = React.lazy(() => import("./Pages/ChatWindow/ChatWindow"));
const OTPVerificationForm = React.lazy(() =>
  import("./Pages/OTPVerification/OTPVerificationForm")
);
const SuccessScreen = React.lazy(() =>
  import("./Pages/OTPSuccess/SuccessScreen")
);
const UpdateDetails = React.lazy(() =>
  import("./Pages/UpdateDetails/updateDetails")
);
const NotFound = React.lazy(() => import("./components/notFound"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#600DB4",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      xmd: 788,
      md: 1000,
      lg: 1150,
      xl: 1536,
    },
  },
});

const App = () => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [chats, setChats] = useState([]);

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const userDetails = useSelector((state) => state.userDetails.userDetails);
  //   const userDetails = useSelector((state) => state.userDetails.userDetails);
  //   const dispatch = useDispatch(loggedIn)
  const dispatch = useDispatch();
  // const navigate =  useNavigate();

  // useEffect(()=>{
  //   let version = localStorage.getItem(LOCAL_STORAGE_DATA.APP_VERSION);

  //   if(version){
  //    if(APP_VERSION !== version){
  //     setTimeout(() => {
  //       localStorage.clear();
  //       dispatch(userActions.clearUserData())
  //       dispatch(loginActions.loggedOut())
  //       Navigate(LOGIN);
  //     }, 2000);
  //     localStorage.setItem(LOCAL_STORAGE_DATA.APP_VERSION,APP_VERSION)
  //    }
  //   } else {
  //     setTimeout(() => {
  //       localStorage.clear();
  //       dispatch(userActions.clearUserData())
  //       dispatch(loginActions.loggedOut())
  //       Navigate(LOGIN);
  //     }, 2000);
  //     localStorage.setItem(LOCAL_STORAGE_DATA.APP_VERSION,APP_VERSION)
  //   }
  // },[])

  const handleLogout = ()=>{
    localStorage.clear();
    dispatch(userActions.clearUserData())
    dispatch(loginActions.loggedOut())
    window.location.replace(LOGIN);

    
  }

  const handleStartNewChat = () => {
    setSelectedChat(null);
  };

  const handleSelectChat = (chat) => {
    apis.chat.getChatByID().then((data) => {
      setSelectedChat({ ...chat, messages: data });
    });
  };

  const handleNewMessage = (message, chatTitle) => {
    // if (chatTitle === undefined) {
    //   apis.chat
    //     .createChat({ message })
    //     .then((data) => {
    //       const newChat = {
    //         id: data.chat_id,
    //         name: data.chat_name,
    //         messages: [{ text: message, sender: "YOU" }],
    //       };
    //       setChats([newChat, ...chats]);
    //       setSelectedChat(newChat);
    //     })
    //     .catch((error) => console.error("Error creating chat:", error));
    // } else {
    //   setChats((prevChats) =>
    //     prevChats.map((c) =>
    //       c.id === selectedChat.id
    //         ? {
    //             ...c,
    //             messages: [...c.messages, { text: message, sender: "YOU" }],
    //           }
    //         : c
    //     )
    //   );
    //   setSelectedChat({
    //     ...selectedChat,
    //     messages: [...selectedChat.messages, { text: message, sender: "YOU" }],
    //   });
    // }
  };
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoaderComponent />}>
      <ErrorBoundary logout={handleLogout}>
          <Router>
            <Routes>
              <Route
                path={LOGIN}
                element={
                  !isLoggedIn ? (
                    <SignupLoginForm />
                  ) : (
                    <Navigate to={CHAT_WITH_AIRA} replace />
                  )
                }
              />
              <Route
                path={HOME}
                element={
                  !isLoggedIn ? (
                    <SignupLoginForm />
                  ) : (
                    <Navigate to={CHAT_WITH_AIRA} replace />
                  )
                }
              />
              <Route
                path={VERIFY_OTP}
                element={
                  !isLoggedIn ? (
                    <OTPVerificationForm />
                  ) : (
                    <Navigate to={CHAT_WITH_AIRA} replace />
                  )
                }
              />
              <Route path={OTP_SUCCESS} element={<SuccessScreen />} />
              <Route path={UPDATE_DATAILS} element={
                  <Protected>
                    <UpdateDetails />
                  </Protected>
                } />
              <Route
                path={CHAT_WITH_AIRA}
                element={
                  <Protected>
                    <ChatWindow
                      selectedChat={selectedChat}
                      chats={chats}
                      onNewMessage={handleNewMessage}
                    />
                  </Protected>
                }
              />
              {/* <Route
              path="/"
              element={
                isLoggedIn ? (
                    <ChatWindow
                    selectedChat={selectedChat}
                    onNewMessage={handleNewMessage}
                  />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            /> */}
              <Route path={FOUR_0_FOUR} element={<NotFound />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
