import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoggedIn: false,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loggedIn: (state) => {
      state.isLoggedIn  = true;
    },
    loggedOut: (state) => {
      state.isLoggedIn =  false
    },
   
  },
})

export const loginActions = loginSlice.actions

export default loginSlice.reducer