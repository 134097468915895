import { Alert, Snackbar } from "@mui/material";
import React from "react";

const AlertMessage = (props) => {
  const { openSnackbar, setOpenSnackbar, setAlertmsg, alertType, alertMsg } =
    props;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar && setOpenSnackbar(false);
    setAlertmsg && setAlertmsg(null); // Clear the error after the Snackbar is closed
  };

  return (
    <Snackbar
      open={openSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={2000}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={alertType || "info"}
        sx={{ width: "100%" }}
      >
        {alertMsg}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
