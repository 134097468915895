import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from './loginSlice'
import userReducer from './userslice'

const reducers = combineReducers({
  login: loginReducer,
  userDetails:userReducer,

});

export default reducers;