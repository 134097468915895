import { Button } from "@mui/material";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Error caught by Error Boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return (
        <center style={{ width: "100%" }} className="error-boundary-container">
          <div className="error-boundary-content">
            <span className="error-boundary-title">
              Something went wrong!
            </span>
            <Button
              type="submit"
              variant="contained"
              className="signup-btn error-boundary-btn"
              color="primary"
              onClick={() => this.props.logout()}
            >
              LOGIN
            </Button>
            <span className="footer-note">
              You're currently using an internal Beta version.
            </span>
          </div>
        </center>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
