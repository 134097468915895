  /* eslint-disable eqeqeq */
  /* eslint-disable no-throw-literal */
import axios from "axios";
import {
  FORBIDDEN,
  INTERNAL_SERVER_ERROR,
  UNAUTHORIZED,
} from "../constants/status-code";
import {
  LOCAL_STORAGE_DATA,
  NO_ACCESS_TO_THE_PAGE,
  SERVER_ERROR,
  SESSION_EXPIRY,
} from "../constants/app-constants";
import { expireSesion } from "../Utils/helperUtils";
// let url = ''
var internals = {};
// const baseUrl = 'http://127.0.0.1:8001/';
const baseUrl = 'https://aira-apis.indiassetz.com/';


internals.requestServer = async function (
  endPoint,
  data,
  method,
  headers,
  contentType,
  auth,
  refresh_token = false
) {
  try {
    let defaultHeaders = {
      Accept: "application/json",
      "Content-Type": contentType || "application/json",
    };
    if (auth) {
      defaultHeaders["Authorization"] = `Bearer ${localStorage.getItem(LOCAL_STORAGE_DATA.ACCESS_TOKEN)}`;
    }
    else if(refresh_token){
      defaultHeaders["Authorization"] = `Bearer ${localStorage.getItem(LOCAL_STORAGE_DATA.REFRESH_TOKEN)}`;
    }
    var params = {
      method: method || "GET",
      headers: Object.assign(headers || {}, defaultHeaders),
    };

    if (data) {
      params.data = data;
    }
    params.url = `${baseUrl}${endPoint}`;

    return axios(params)
      .then(function (response) {
        return {data: response.data,
              status:response.status
        };
      })
      .then(function (response) {

        return response;
      })

      .catch(function (error) {
        if (!error.response) {
          return {
            status: 500,
            message: SERVER_ERROR,
          };
        }
        switch (error.status) {
          case FORBIDDEN:
            // return {
            //   status: FORBIDDEN,
            //   message: NO_ACCESS_TO_THE_PAGE,
            // };
            expireSesion()
            break;
          case UNAUTHORIZED:
            expireSesion()
            // return {
            //   status: UNAUTHORIZED,
            //   message: SESSION_EXPIRY,
            // };
            break;
          case INTERNAL_SERVER_ERROR:
            expireSesion()
            // return {
            //   status: UNAUTHORIZED,
            //   message: SESSION_EXPIRY,
            // };
            break;
          default:
            return {
              status: error?.status || INTERNAL_SERVER_ERROR,
              message: error?.message || SERVER_ERROR,
            };
        }
        // console.log(error)
      });
  } catch (error) {
    // return {
    //   status: FORBIDDEN,
    //   message: SESSION_EXPIRY,
    // };
    console.log(error)
    expireSesion()

  }
};

export default internals.requestServer;
